import { PublicLayout } from '@app/layout/PublicLayout';
import { ErrorStub } from '@shared/ui';

import type { RouteObject } from 'react-router-dom';

const errorElement = <ErrorStub />;

export const publicRouter: RouteObject[] = [
  {
    element: <PublicLayout />,
    errorElement,
    children: [
      {
        path: '/auth',
        errorElement,
        lazy: async () => {
          const { AuthPage } = await import('@pages/AuthPage');

          return { Component: AuthPage };
        },
      },
    ],
  },
];
